<template>
  <v-card>
    <v-card-title class="text-h4 grey lighten-2">
      <span style="margin-bottom: 5px" class="broadcast-sm me-2">{{ $t('broadcasts.broadcastTimeline') }}</span>
      <v-tooltip v-if="statusChannelsBanner && bannerAlertText" bottom color="warning">
        <template #activator="{ on, attrs }">
          <v-icon v-bind="attrs" color="warning" v-on="on">
            {{ icons.mdiAlert }}
          </v-icon>
        </template>
        <span>{{ bannerAlertText }}</span>
      </v-tooltip>

      <v-row justify="end">
        <v-col cols="12" md="4">
          <v-btn block color="primary">
            <v-icon left>
              {{ icons.mdiExport }}
            </v-icon>
            <vue-excel-xlsx
              :data="broadcastList"
              :columns="columnsScheduledBroadcastAll"
              :file-name="'ScheduledBroadcast'"
              :file-type="'xlsx'"
              :sheet-name="'ScheduledBroadcast'"
              class="primary-text"
            >
              {{ $t('exportToCsv') }}
            </vue-excel-xlsx>
          </v-btn>
        </v-col>
        <v-col cols="12" md="4">
          <v-btn
            block
            :disabled="!serverConnectionStatus"
            color="primary"
            @click="
              addBroadcastDialog = true
              newBroadcast += 1
            "
          >
            <v-icon left>
              {{ icons.mdiPlusCircle }}
            </v-icon>
            Broadcast
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <div
          :class="[`text-subtitle-2`, `ml-2`, `mt-0`, `mb-2`, `font-weight-bold`, `red--text`]"
          v-html="$t('broadcasts.notesSendingBroadcasts')"
        ></div>
      </v-row>
    </v-card-text>

    <!-- Calendar -->
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn outlined class="" color="grey darken-2" @click="setToday">
              <span class="selectButton-sm"> {{ $t('broadcasts.today') }}</span>
            </v-btn>
            <v-btn fab text small class="shet-toolbar" color="grey darken-2" @click="prev">
              <v-icon small>
                {{ icons.mdiChevronLeft }}
              </v-icon>
            </v-btn>
            <v-btn fab text small class="shet-toolbar" color="grey darken-2" @click="next">
              <v-icon small>
                {{ icons.mdiChevronRight }}
              </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar" class="shet-toolbar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span class="selectButton-sm">{{ typeToLabel[type] }}</span>
                  <v-icon right>
                    {{ icons.mdiMenuDown }}
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>
                    <span>{{ $t('broadcasts.day') }}</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>
                    <span>{{ $t('broadcasts.week') }}</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>
                    <span>{{ $t('broadcasts.month') }}</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>
                    <span> {{ $t('broadcasts.four_day') }}</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="64" class="shet-toolbar-lg">
          <v-toolbar flat>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small>
                {{ icons.mdiChevronLeft }}
              </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small>
                {{ icons.mdiChevronRight }}
              </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            :type="type"
            :locale="locale"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
          >
            <template #day-label="{ date, day, present }">
              <v-btn
                fab
                small
                :color="hasEvent(date) ? 'info' : present ? 'primary' : ''"
                :disabled="false"
                class="mb-1"
                @click="viewDay({ date })"
              >
                <span v-text="day" />
              </v-btn>
            </template>
          </v-calendar>
          <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
            <v-card color="grey lighten-4" max-width="350px" flat>
              <v-toolbar :color="selectedEvent.color">
                <v-btn icon>
                  <v-icon color="white" large>
                    {{ icons.mdiAlarm }}
                  </v-icon>
                </v-btn>
                <v-toolbar-title class="white--text"> Detail Broadcast </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn class="pa-8" icon @click="selectedOpen = false">
                  <v-icon large color="white">
                    {{ icons.mdiCloseCircle }}
                  </v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text class="pa-3">
                <v-row>
                  <v-col cols="12" lg="12">
                    <table class="billing-address-table">
                      <tr>
                        <td class="billing-title">
                          <p class="text-no-wrap text--primary font-weight-medium">
                            {{ $t('broadcasts.broadcastName') }}
                          </p>
                        </td>
                        <td>
                          <p><strong> : </strong>{{ selectedEvent.name || '' }}</p>
                        </td>
                      </tr>
                      <tr>
                        <td class="billing-title">
                          <p class="text-no-wrap text--primary font-weight-medium">
                            {{ $t('broadcasts.messageTemplate') }}
                          </p>
                        </td>
                        <td>
                          <p><strong> : </strong>{{ selectedEvent.template_name || '' }}</p>
                        </td>
                      </tr>
                      <tr>
                        <td class="billing-title">
                          <p class="text-no-wrap text--primary font-weight-medium">
                            {{ $t('broadcasts.numberContacts') }}
                          </p>
                        </td>
                        <td>
                          <p><strong> : </strong>{{ selectedEvent.contacts || '' }}</p>
                        </td>
                      </tr>
                      <tr>
                        <td class="billing-title">
                          <p class="text-no-wrap text--primary font-weight-medium">
                            {{ $t('broadcasts.executionDatetime') }}
                          </p>
                        </td>
                        <td>
                          <p>
                            <strong> : </strong
                            >{{ selectedEvent.start ? selectedEvent.start.toISOString().substring(0, 10) : '' }}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="billing-title">
                          <p class="text-no-wrap text--primary font-weight-medium">
                            {{ $t('broadcasts.sendingSpeed') }}
                          </p>
                        </td>
                        <td>
                          <p><strong> : </strong>{{ selectedEvent.sendSpeed }}</p>
                        </td>
                      </tr>
                      <tr>
                        <td class="billing-title">
                          <p class="text-no-wrap text--primary font-weight-medium">Track Link</p>
                        </td>
                        <td>
                          <p><strong> : </strong>{{ selectedEvent.trackLink }}</p>
                        </td>
                      </tr>
                      <tr>
                        <td class="billing-title">
                          <p class="text-no-wrap text--primary font-weight-medium">Allow Unsubscribe</p>
                        </td>
                        <td>
                          <p><strong> : </strong>{{ selectedEvent.unsubscribe }}</p>
                        </td>
                      </tr>
                      <tr>
                        <td class="billing-title">
                          <p class="text-no-wrap text--primary font-weight-medium">Delivered</p>
                        </td>
                        <td>
                          <p>
                            <strong> : </strong>
                            <v-progress-circular
                              :rotate="-90"
                              :size="50"
                              :width="5"
                              color="success"
                              :value="selectedEvent.delivered"
                            >
                              {{ selectedEvent.delivered }}%
                            </v-progress-circular>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="billing-title">
                          <p class="text-no-wrap text--primary font-weight-medium">Failed</p>
                        </td>
                        <td>
                          <p><strong> : </strong> {{ selectedEvent.failed }}</p>
                        </td>
                      </tr>
                      <tr>
                        <td class="billing-title">
                          <p class="text-no-wrap text--primary font-weight-medium">Status</p>
                        </td>
                        <td>
                          <p>
                            :
                            <v-chip
                              :color="statusColor[selectedEvent.status]"
                              :class="`${statusColor[selectedEvent.status]}--text`"
                              class="v-chip-${isDark ? 'dark' : 'light'}-bg"
                              small
                            >
                              {{ selectedEvent.status }}
                            </v-chip>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="billing-title" colspan="2">
                          <p class="text-no-wrap text--primary font-weight-medium">Statistic :</p>
                          <p>
                            <v-chip color="warning" small class="chip-margin-left">
                              {{ $t('broadcasts.sent') }} :
                              {{
                                selectedEvent.statistic
                                  ? selectedEvent.statistic.sent
                                    ? selectedEvent.statistic.sent
                                    : 0
                                  : 0
                              }}
                            </v-chip>
                            <v-chip color="secondary" small class="chip-margin-left">
                              {{ $t('broadcasts.received') }} :
                              {{
                                selectedEvent.statistic
                                  ? selectedEvent.statistic.received
                                    ? selectedEvent.statistic.received
                                    : 0
                                  : 0
                              }}
                            </v-chip>
                            <v-chip color="info" small class="chip-margin-left">
                              {{ $t('broadcasts.read') }} :
                              {{
                                selectedEvent.statistic
                                  ? selectedEvent.statistic.read
                                    ? selectedEvent.statistic.read
                                    : 0
                                  : 0
                              }}
                            </v-chip>
                            <v-chip color="success" small class="chip-margin-left">
                              {{ $t('broadcasts.replied') }} :
                              {{
                                selectedEvent.statistic
                                  ? selectedEvent.statistic.replied
                                    ? selectedEvent.statistic.replied
                                    : 0
                                  : 0
                              }}
                            </v-chip>
                          </p>
                        </td>
                      </tr>
                    </table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
    <!-- addBroadcast Dialog -->
    <v-dialog v-model="addBroadcastDialog" max-width="1000" scrollable>
      <template v-slot:default="">
        <v-card elevation="0">
          <v-toolbar color="primary">
            <div class="text-h5 white--text">{{ $t('create') }} Broadcast</div>
            <v-spacer></v-spacer>
            <v-btn class="pa-8" icon @click="addBroadcastDialog = false">
              <v-icon x-large color="white">
                {{ icons.mdiCloseCircle }}
              </v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <add-broadcast
              :key="newBroadcast"
              :data-contacts-from-api="dataContactsFromApi"
              @close-dialog="closeDialog()"
            ></add-broadcast>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
    <!-- dialog redirect -->
    <v-dialog v-model="redirectDialog" persistent max-width="400">
      <v-card color="primary">
        <v-card-text class="pt-3 white--text">
          {{ $t('broadcasts.loginExpired') }}
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- dialog loading -->
    <v-dialog v-model="loadingDialog" hide-overlay persistent width="450">
      <v-card color="primary">
        <v-card-text class="pt-3 white--text">
          {{ loadingText }}
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
/* eslint-disable */
import {
  mdiAlarm,
  mdiAlert,
  mdiChartBar,
  mdiChevronLeft,
  mdiChevronRight,
  mdiCloseCircle,
  mdiDeleteOutline,
  mdiExport,
  mdiEyeOutline,
  mdiMagnify,
  mdiMenuDown,
  mdiPlusCircle,
} from '@mdi/js'

// import custom
import { default as clientPocketBase } from '@/helpers/pocketbase'
import pouchDbMixin from '../../mixins/pouchDbMixin'
import AddBroadcast from './AddBroadcast.vue'
//export excel
export default {
  components: { AddBroadcast },
  computed: {
    bannerAlertText() {
      return this.$store.getters['inbox/getBannerAlertText']
    },
    statusChannelsBanner() {
      return this.$store.getters['global/getStatusChannels']
    },
    locale() {
      return this.$i18n.locale
    },
    typeToLabelText() {
      return {
        month: this.$t('broadcasts.month'),
        week: this.$t('broadcasts.week'),
        day: this.$t('broadcasts.day'),
        '4day': this.$t('broadcasts.four_day'),
      }
    },
    themeColor() {
      let color = ''
      if (this.$vuetify.theme.isDark) {
        color = 'primary'
      } else {
        color = 'white'
      }

      return color
    },
  },
  setup() {
    const statusColor = {
      Processing: 'info',
      Done: 'success',
      Cancelled: 'error',
      Draft: 'secondary',
      Waiting: 'warning',
    }

    return {
      icons: {
        mdiAlert,
        mdiMagnify,
        mdiDeleteOutline,
        mdiPlusCircle,
        mdiExport,
        mdiChartBar,
        mdiEyeOutline,
        mdiCloseCircle,
        mdiAlarm,
        mdiChevronLeft,
        mdiChevronRight,
        mdiMenuDown,
      },
      statusColor,
    }
  },
  mixins: [pouchDbMixin],
  data: () => ({
    addBroadcastDialog: false,
    newBroadcast: 1,
    detailDialog: false,
    statisticDialog: false,
    detailBroadcast: {},
    detailListStatus: [],
    detailListContact: [],
    singleBroadcastName: '',
    singleMessageTemplates: '',
    singleNumberOfContacts: '',
    singleExecutionDate: '',
    broadcastList: [],
    focus: '',
    type: 'month',
    typeToLabel: {},
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    colors: ['primary', 'warning', 'error', 'info', 'secondary'],
    redirectDialog: false,
    columnsScheduledBroadcastAll: [
      {
        label: 'Channel',
        field: 'sender.label_server',
      },
      {
        label: 'Broadcast Name',
        field: 'broadcast_name',
      },
      {
        label: 'Broadcast Time',
        field: 'broadcast_time',
      },
      {
        label: 'Elapsed Date / Time',
        field: 'createdAt',
      },
      {
        label: 'Message Template',
        field: 'message_template.message',
      },
      {
        label: 'Recipients',
        field: 'recipients',
      },
    ],
    loadingDialog: false,
    dataContactsFromApi: null,
    loadingText: '',
    eventDateContainer: [],
  }),
  beforeDestroy() {
    this.$eventBus.$off(process.env.VUE_APP_EVENT_UPDATE_BROADCAST)
  },
  async mounted() {
    // cek token life
    const tokenLife = await this.$store.dispatch('auth/checkTokenLife', { token: this.user.token })
    if (tokenLife.statusCode === 401) {
      this.redirectDialog = true
      setTimeout(() => {
        this.$router.replace({ name: 'login' })
      }, 1500)
    }
    this.loadingDialog = true
    this.loadingText = 'Preparing Broadcast Data'
    this.typeToLabel = this.typeToLabelText
    let conn = await this.checkStatusConnectionServer()

    // await this.mixinConnectBroadcasts(false)
    this.getBroadcasts(conn).then(e => {
      this.loadingDialog = false
      this.$refs.calendar.checkChange()
      // if (conn) this.replicateFromPouchDbGlobal(process.env.VUE_APP_DB_BROADCASTS_NAME, this.user)
    })

    // this.$eventBus.$on(process.env.VUE_APP_EVENT_UPDATE_BROADCAST, async payload => {
    //   this.$store.commit('global/setServerConnectionStatus', true)
    //   let temp = await this.mixinRestructureOnchangeBroadcast([...this.broadcastList], payload)
    //   this.broadcastList = [...temp]
    //   const eventData = this.mapEventBroadcast(temp)
    //   this.eventDateContainer = eventData.eventDate
    //   this.events = eventData.data
    //   this.$refs.calendar.checkChange()
    // })

    this.$store
      .dispatch('broadcast/getContacts', {
        subId: this.user.sub_id,
        token: this.user.token,
      })
      .then(response => {
        //console.log(response, ' == response')

        // this.isLoadContactsDone = true
        this.dataContactsFromApi = response
      })
      .catch(err => {
        console.log(err, ' == error')
      })
  },
  methods: {
    mapEventBroadcast(broadcastsData) {
      console.log(broadcastsData, `INI DATA VIRA`)
      let eventDate = []
      let data = []
      if (broadcastsData) {
        data = broadcastsData.map(el => {
          // //console.log(el.expand.broadcast_id, `INI DATA VIRA NAME`)
          let dateString = new Date(Math.floor(el.schedule_send / 1000) * 1000).toDateString()
          eventDate.push(dateString)
          return {
            name: el.expand?.broadcast_id.name,
            start: new Date(Math.floor(el.schedule_send / 1000) * 1000),
            color: 'info',
            timed: true,
            template_name: el.message.template_name,
            contacts: broadcastsData.length,
            status: el.expand?.broadcast_id.status,
            statistic: el.expand?.broadcast_id.statistic,
            delivered: Math.floor(
              (100 * (el.expand?.broadcast_id.statistic.sent + el.expand?.broadcast_id.statistic.skipped)) /
                (el.expand?.broadcast_id.statistic.unsent +
                  el.expand?.broadcast_id.statistic.skipped +
                  el.expand?.broadcast_id.statistic.sent),
            ),
            failed: el.expand?.broadcast_id.statistic.skipped,
            // trackLink: el.expand?.unsubscribe ? 'True' : 'False',
            // sendSpeed: el.delay_type ? el.delay_type[0].toUpperCase() + el.delay_type.slice(1) : 'Fast',
            // unsubscribe: el.unsubscribe ? 'True' : 'False',
          }
        })
      }
      this.loadingDialog = false
      console.log({ data: data, eventDate: [...this.eventDateContainer, ...eventDate] }, '<< fix done vira')
      return { data: data, eventDate: [...this.eventDateContainer, ...eventDate] }
    },
    wrapCsvValue(val, formatFn) {
      let formatted = formatFn !== void 0 ? formatFn(val) : val

      formatted = formatted === void 0 || formatted === null ? '' : String(formatted)

      formatted = formatted.split('"').join('""')
      /**
       * Excel accepts \n and \r in strings, but some other CSV parsers do not
       * Uncomment the next two lines to escape new lines
       */
      // .split('\n').join('\\n')
      // .split('\r').join('\\r')

      return `"${formatted}"`
    },
    exportTable() {
      //console.log(this.broadcastList, 'ini ya')
      const content = [this.columnsScheduledBroadcastAll.map(col => this.wrapCsvValue(col.label))]
        .concat(
          this.broadcastList.map(row =>
            this.columnsScheduledBroadcastAll
              .map(col => {
                if (col.field === 'sender') {
                  return row.channel
                } else if (col.field === 'message_template') {
                  return row.message_template.template_name
                } else {
                  return this.wrapCsvValue(
                    typeof col.field === 'function' ? col.field(row) : row[col.field === void 0 ? col.name : col.field],
                    col.format,
                  )
                }
              })
              .join(','),
          ),
        )
        .join('\r\n')

      const anchor = document.createElement('a')
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(content)
      anchor.target = '_blank'
      anchor.download = new Date().getTime() + '-BroadCast-Schedule.csv'
      anchor.click()
    },
    async getBroadcasts(remote = false) {
      // let tempData1 = await this.mixinLoadBroadcasts('all', remote)
      const tempData = await clientPocketBase.collection('crm_broadcasts').getFullList(200)
      let tempDataBcMsg
      for (let i = 0; i < tempData.length; i++) {
        tempDataBcMsg = await clientPocketBase.collection('crm_broadcast_messages').getFullList(200, {
          filter: `broadcast_id = '${tempData[i].id}'`,
          expand: 'broadcast_id',
        })
      }
      const eventData = this.mapEventBroadcast(tempDataBcMsg)
      this.eventDateContainer = eventData.eventDate
      this.events = eventData.data
      this.broadcastList = [...tempData.data]
    },
    closeDialog() {
      this.addBroadcastDialog = false
    },
    viewDay({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor(event) {
      return event.color
    },
    setToday() {
      this.focus = ''
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    hasEvent(date) {
      let event = this.eventDateContainer.includes(new Date(date).toDateString())
      return event
    },
  },
  watch: {
    locale() {
      this.typeToLabel = this.typeToLabelText
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
.chip-margin-left {
  margin-left: 1px;
}
@media only screen and (max-width: 578px) {
  .shet-toolbar {
    display: none;
  }
  .broadcast-sm {
    font-size: 28px;
  }
  .selectButton-sm {
    font-size: 12px;
  }
}
@media only screen and (min-width: 578px) {
  .shet-toolbar-lg {
    display: none;
  }
}
@media only screen and (max-width: 350px) {
  .broadcast-sm {
    font-size: 20px;
  }
  .selectButton-sm {
    font-size: 10px;
  }
}
</style>
